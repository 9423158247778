<template>
  <li class="menu-wrapper__list-item" @click="closeMenu()">
    <uc-link
      class="menu-wrapper__list-item__link folder menu-wrapper__list-item__link-outlet"
      title="Outlet"
      aria-label="Outlet"
      :to="localePath({ name: 'outlet-page' })"
    >
      <span class="menu-wrapper__list-item__link-title">Outlet</span>
      <div class="menu-wrapper__list-item__link-img">
        <img :src="`https://static.ma.com.ua/ma/svg/menu/outlet.svg`" alt="outlet" />
      </div>
    </uc-link>
  </li>
</template>

<script>
export default {
  methods: {
    closeMenu() {
      this.$parent.closeMobileMenu();
    }
  }
};
</script>
