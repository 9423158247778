<template>
  <li
    v-if="isBrandsShow && menuBrandList && menuBrandList.length"
    :class="{ hovered: isHovered }"
    class="navigation-list__item navigation-list__brands"
    @mouseenter="mouseEnterHandler()"
    @mouseleave="mouseLeaveHandler()"
    @click="hideMenu()"
  >
    <uc-link
      :to="localePath({ name: 'brand-list' })"
      :title="$t('Бренды')"
      aria-label="Бренды"
      class="navigation-list__link"
      ><div class="navigation-ico">
        <img :src="`https://static.ma.com.ua/ma/svg/menu/brands.svg`" height="21" width="21" alt="brands" />
      </div>
      <span>{{ $t('Бренды') }}</span
      ><img class="navigation-arrow" src="https://static.ma.com.ua/ma/svg/nav-chevron.svg" alt="arrow"
    /></uc-link>
    <div class="dropdowns-menu">
      <div class="dropdowns-menu-wrap">
        <div class="dropdowns-menu__col dropdowns-menu__col_brand">
          <div class="dropdowns-menu-column dropdowns-menu-column_brand">
            <div class="dropdowns-menu__head">{{ $t('Бренды') }}</div>
            <div class="menu-list_brand-column-list">
              <div
                v-for="(column, columnKey) in menuBrandList"
                :key="`brand-column-list-${columnKey}`"
                class="menu-list_brand-column"
              >
                <ul class="menu-list menu-list_brand">
                  <li v-for="(brand, key) in column" :key="`menu-list_brand-${key}`" class="menu-list__item">
                    <uc-link
                      :to="localePath({ name: 'brand-show', params: { slug: brand.slug } })"
                      class="menu-list__text menu-list__text_drop"
                      :title="brand.title"
                      :aria-label="brand.title"
                    >
                      <div class="menu-list__text-image">
                        <uc-image :img="brand.menuImage" :height="`52`" folder="content-image" />
                      </div>
                      {{ brand.title || '' }}
                    </uc-link>
                  </li>
                  <li class="menu-list__item">
                    <uc-link :to="localePath({ name: 'brand-list' })" class="menu-list__text-all-brands">
                      {{ $t('Показать все') }}
                    </uc-link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div v-if="false" class="dropdowns-menu__col dropdowns-menu__col_brand">
          <div class="dropdowns-menu-column">
            <ul class="menu-list menu-list_brand">
              <li v-for="(brand, key) in imageBrandList" :key="`menu-list-item-${key}`" class="menu-list__item">
                <uc-link
                  :to="localePath({ name: 'brand-show', params: { slug: brand.slug } })"
                  class="menu-list__text menu-list__text_drop"
                >
                  <uc-image :img="brand.menuImage" folder="content-image" :width="`169`" :height="`113`" />
                </uc-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </li>
</template>

<script>
import ScreenWidth from '~/src/mixins/ScreenWidth';
import MouseHandlerMixin from '~/src/components/navigation/mixin/MouseHandler.mixin.js';

const chunk = (list, size) => {
  const result = [];
  for (let i = 0; i < list.length; i += size) {
    result.push(list.slice(i, i + size));
  }
  return result;
};

export default {
  name: 'NavigationBrands',
  mixins: [ScreenWidth, MouseHandlerMixin],
  data: function () {
    return {
      countColumn: 4,
      countRow: 4
    };
  },
  computed: {
    isBrandsShow() {
      return process.env.BRANDS_SHOW;
    },
    menuBrandList() {
      const brandList = (this.$store.state.catalog.brandList || [])
        .filter((brand) => brand.inMenu)
        .slice(0, this.countColumn * this.countRow);

      return chunk(brandList, this.countRow);
    },
    imageBrandList() {
      return this.menuBrandList.filter((brand) => !!brand.menuImage);
    }
  },
  watch: {
    isScreenSmallDesktop: function () {
      this.countColumn = 2;
      this.countRow = 8;
    }
  }
};
</script>
