<template>
  <div
    v-click-outside="hideWidgetClick"
    class="client-info-widget"
    @mouseover="displayWidgetMouse()"
    @mouseleave="hideWidgetMouse()"
  >
    <div class="client-info-widget__button" @click="displayWidgetClick()">
      <img
        class="widget-button__img"
        src="https://static.ma.com.ua/assets/svg/header/customer-info-ico.svg"
        alt="basket"
      />
      <div class="widget-button__text">{{ $t('Покупателю') }}</div>
    </div>

    <div v-if="isVisible" class="client-info-widget__block">
      <div class="client-info-widget__wrapper">
        <uc-link
          :to="localePath({ name: 'info-page', params: { slug: 'about-shop' } })"
          :title="$t('О нас')"
          :aria-label="$t('О нас')"
          class="widget-wrapper__link"
          >{{ $t('О нас') }}</uc-link
        >
        <uc-link
          :to="localePath({ name: 'info-page', params: { slug: 'shops' } })"
          :title="$t('Наши магазины')"
          :aria-label="$t('Наши магазины')"
          class="widget-wrapper__link"
        >
          {{ $t('Наши магазины') }}
        </uc-link>
        <uc-link
          :to="localePath({ name: 'info-page', params: { slug: 'delivery' } })"
          :title="$t('Доставка и оплата')"
          :aria-label="$t('Доставка и оплата')"
          class="widget-wrapper__link"
        >
          {{ $t('Доставка и оплата') }}
        </uc-link>
        <uc-link
          :to="localePath({ name: 'info-page', params: { slug: 'return-policy' } })"
          :title="$t('Возврат и обмен')"
          :aria-label="$t('Возврат и обмен')"
          class="widget-wrapper__link"
        >
          {{ $t('Возврат и обмен') }}
        </uc-link>
        <uc-link
          :to="localePath({ name: 'info-page', params: { slug: 'service-warranty' } })"
          :title="$t('Гарантия и сервис')"
          :aria-label="$t('Гарантия и сервис')"
          class="widget-wrapper__link"
        >
          {{ $t('Гарантия и сервис') }}
        </uc-link>
        <uc-link
          v-if="isActiveBonusProgram"
          :to="localePath({ name: 'info-page', params: { slug: 'bonus-rules' } })"
          :title="$t('МА Кешбэк')"
          :aria-label="$t('МА Кешбэк')"
          class="widget-wrapper__link"
        >
          {{ $t('МА Кешбэк') }}
        </uc-link>
        <uc-link
          v-if="isActiveBonusProgram"
          :to="localePath({ name: 'info-page', params: { slug: 'subscription' } })"
          :title="$t('МА Підписка')"
          :aria-label="$t('МА Підписка')"
          class="widget-wrapper__link"
        >
          {{ $t('МА Підписка') }}
        </uc-link>
        <uc-link
          v-if="isActiveBonusProgram"
          :to="localePath({ name: 'promotion-page' })"
          :title="$t('Акции и распродажи')"
          :aria-label="$t('Акции и распродажи')"
          class="widget-wrapper__link"
        >
          {{ $t('Акции и распродажи') }}
        </uc-link>
        <uc-link
          :to="localePath({ name: 'pakunok-malyuka-page' })"
          :title="$t('Пакунок малюка')"
          :aria-label="$t('Пакунок малюка')"
          class="widget-wrapper__link"
        >
          {{ $t('Пакунок малюка') }}
        </uc-link>
        <uc-link
          :to="localePath({ name: 'info-page', params: { slug: 'gift-sertificate' } })"
          :title="$t('Подарочные сертификаты')"
          :aria-label="$t('Подарочные сертификаты')"
          class="widget-wrapper__link"
        >
          {{ $t('Подарочные сертификаты') }}
        </uc-link>
        <uc-link
          :to="localePath({ name: 'blog-page' })"
          :title="$t('Блог')"
          :aria-label="$t('Блог')"
          class="widget-wrapper__link"
        >
          {{ $t('Блог') }}
        </uc-link>
      </div>
    </div>
  </div>
</template>

<script>
import vClickOutside from 'v-click-outside';
import { RouterMixin } from 'u-mixins';
import BonusProgramMemberMixin from '~/src/mixins/BonusProgramMember';
import ScreenWidthMixin from '~/src/mixins/ScreenWidth';

export default {
  directives: {
    clickOutside: vClickOutside.directive
  },
  mixins: [RouterMixin, BonusProgramMemberMixin, ScreenWidthMixin],
  data: () => {
    return {
      isVisible: false
    };
  },
  watch: {
    '$route.path': {
      handler() {
        this.hideWidget();
      }
    }
  },
  methods: {
    displayWidgetMouse() {
      if (!this.isScreenDesktop) {
        return;
      }

      this.isVisible = true;
    },
    displayWidgetClick() {
      if (this.isScreenDesktop) {
        return;
      }

      this.isVisible = true;
    },
    hideWidget() {
      this.isVisible = false;
    },
    hideWidgetMouse() {
      if (!this.isScreenDesktop) {
        return;
      }

      this.isVisible = false;
    },
    hideWidgetClick() {
      if (this.isScreenDesktop) {
        return;
      }

      this.isVisible = false;
    }
  }
};
</script>

<style lang="scss" src="~/assets/scss/client-info-widget/_client-info-widget.scss" />
