<template>
  <section class="mobile-menu--visibility">
    <div v-if="menuCategories.length" class="burger" @click.stop="toggleMobileMenu">
      <img class="burger__item" src="https://static.ma.com.ua/ma/svg/burger.svg" alt="burger" />
    </div>
    <div v-click-outside="closeMobileMenu" :class="{ 'is-open': mobileMenu }" class="mobile-menu">
      <div class="close-overlay" @click="closeMobileMenu">
        <img src="https://static.ma.com.ua/assets/img/main/close.svg" alt="close" />
      </div>
      <div :class="{ 'is-show': mobileMenu }" class="mobile-menu__wrap">
        <div class="mobile-menu-head">
          <uc-link :to="localePath({ name: 'main' })" class="mobile-menu-head__logo">
            <img src="https://static.ma.com.ua/ma/svg/ma-logo.svg" alt="ma-logo" @click="closeMobileMenu" />
          </uc-link>
          <language-switcher class="mobile-menu-head__item" silent />
          <div class="mobile-menu-head__close" @click="closeMobileMenu">
            <img src="https://static.ma.com.ua/ma/svg/menu/mobile-nav-close.svg" alt="close" />
          </div>
        </div>
        <div class="mobile-menu-body">
          <div v-if="screenWidth < TABLET_SCREEN">
            <div class="mobile-menu-wrapper" :class="{ 'display-none': isSubCategories || brandVisibility }">
              <ul class="menu-wrapper__list">
                <li
                  v-for="(category, index) in menuCategories"
                  :key="`mobile-catigories-${index}`"
                  class="menu-wrapper__list-item"
                  @click="setCategory(category)"
                >
                  <div class="menu-wrapper__list-item__link folder">
                    <span class="menu-wrapper__list-item__link-title">{{ $t(category.title) }}</span>
                    <div class="menu-wrapper__list-item__link-img">
                      <img :src="`https://static.ma.com.ua/ma/svg/menu/${category.slug}.svg`" :alt="category.slug" />
                    </div>
                  </div>
                </li>
                <li
                  v-if="isBrandsShow && menuBrandList && menuBrandList.length"
                  class="menu-wrapper__list-item"
                  @click="toBrands()"
                >
                  <div class="menu-wrapper__list-item__link folder">
                    <span class="menu-wrapper__list-item__link-title">{{ $t('Бренды') }}</span>
                    <div class="menu-wrapper__list-item__link-img">
                      <img :src="`https://static.ma.com.ua/ma/svg/menu/brands.svg`" alt="brands" />
                    </div>
                  </div>
                </li>
                <mobile-outlet />
              </ul>
            </div>
            <div v-show="isSubCategories && !isFilteredSubCategories" class="mobile-menu-wrapper">
              <div class="menu-wrapper__list-item__link" @click="toCategory()">
                <img
                  class="menu-wrapper__list-item__link-arrow__back"
                  src="https://static.ma.com.ua/assets/svg/mobile-back.svg"
                  alt="arrow"
                />
                <span class="menu-wrapper__list-item__link-title">{{ categoryTitle }}</span>
              </div>
              <div class="wrapper-list__item-show-all" @click="closeMobileMenu()">
                <uc-link
                  v-if="folderCategory.slug"
                  :to="localePath({ name: 'product-catalog-page', params: buildParams(folderCategory.slug) })"
                  class="menu-wrapper__list-item__link"
                >
                  {{ $t('Показать все') }}
                </uc-link>
              </div>
              <ul class="menu-wrapper__list">
                <div v-for="(category, index) in subCategories" :key="`sub-categories-${index}`">
                  <div v-if="category.childList && category.childList.length">
                    <li
                      v-if="folderCategory.slug === 'odezhda' || folderCategory.slug === 'obuv'"
                      class="menu-wrapper__list-item"
                      @click="setSubCategory(category)"
                    >
                      <div class="menu-wrapper__list-item__link">
                        <span>{{ $t(category.title) }}</span>
                        <img
                          class="menu-wrapper__list-item__link-arrow"
                          src="https://static.ma.com.ua/ma/svg/nav-chevron.svg"
                          alt="arrow"
                        />
                      </div>
                    </li>
                  </div>
                  <li
                    v-if="folderCategory.slug !== 'odezhda' && folderCategory.slug !== 'obuv'"
                    class="menu-wrapper__list-item"
                    @click="closeMobileMenu()"
                  >
                    <uc-link
                      :to="
                        localePath({ name: 'product-catalog-page', params: buildParams(folderCategory.slug, category) })
                      "
                      class="menu-wrapper__list-item__link"
                      >{{ $t(category.title) }}</uc-link
                    >
                  </li>
                </div>
              </ul>
            </div>
            <div v-show="isSubCategories && isFilteredSubCategories" class="mobile-menu-wrapper">
              <div class="menu-wrapper__list-item" @click="toSubCategory()">
                <div class="menu-wrapper__list-item__link">
                  <img
                    class="navigation-list__link_mobile_back"
                    src="https://static.ma.com.ua/assets/svg/mobile-back.svg"
                    alt="arrow"
                  />
                  <span class="menu-wrapper__list-item__link-folder-item">{{ $t(subCategoryTitle) }}</span>
                  <span class="menu-wrapper__list-item__link-folder-title">{{ $t(categoryTitle) }}</span>
                </div>
              </div>
              <ul class="menu-wrapper__list">
                <li
                  v-for="(category, index) in filteredSubCategories"
                  :key="`filtered-sub-categories-${index}`"
                  class="menu-wrapper__list-item"
                  @click="closeMobileMenu()"
                >
                  <uc-link
                    :to="
                      localePath({
                        name: 'product-catalog-page',
                        params: buildParams(folderCategory.slug, category, gender)
                      })
                    "
                    class="menu-wrapper__list-item__link"
                    >{{ $t(category.title) }}</uc-link
                  >
                </li>
              </ul>
            </div>
            <div v-show="brandVisibility" class="mobile-menu-wrapper">
              <div class="menu-wrapper__list-item__link" @click="outBrands()">
                <img
                  class="navigation-list__link_mobile_back"
                  src="https://static.ma.com.ua/assets/svg/mobile-back.svg"
                  alt="arrow"
                />
                <span class="menu-wrapper__list-item__link-title">{{ $t('Бренды') }}</span>
              </div>
              <div class="wrapper-list__item-show-all" @click="closeMobileMenu()">
                <uc-link :to="localePath({ name: 'brand-list' })" class="menu-wrapper__list-item__link">
                  {{ $t('Показать все') }}
                </uc-link>
              </div>
              <ul class="menu-wrapper__list">
                <li
                  v-for="(brand, index) in menuBrandList"
                  :key="`menu-brand-list-${index}`"
                  class="menu-wrapper__list-item"
                  @click="closeMobileMenu()"
                >
                  <uc-link
                    :to="localePath({ name: 'brand-show', params: { slug: brand.slug } })"
                    class="menu-wrapper__list-item__link"
                    >{{ brand.title || '' }}</uc-link
                  >
                </li>
              </ul>
            </div>
          </div>

          <a href="tel:0443230906" class="mobile-menu-contact">
            <svg>
              <use xlink:href="#top-phone" /></svg
            >(044) 323-09-06</a
          >
          <ul class="mobile-menu-sublist">
            <li class="mobile-menu-sublist__item" @click="closeMobileMenu">
              <uc-link
                :to="localePath({ name: 'info-page', params: { slug: 'about-shop' } })"
                class="mobile-menu-sublist__link"
                >{{ $t('О нас') }}</uc-link
              >
            </li>
            <li class="mobile-menu-sublist__item" @click="closeMobileMenu">
              <uc-link
                :to="localePath({ name: 'info-page', params: { slug: 'shops' } })"
                class="mobile-menu-sublist__link"
                >{{ $t('Наши магазины') }}</uc-link
              >
            </li>
            <li class="mobile-menu-sublist__item" @click="closeMobileMenu">
              <uc-link
                :to="localePath({ name: 'info-page', params: { slug: 'delivery' } })"
                class="mobile-menu-sublist__link"
                >{{ $t('Доставка и оплата') }}</uc-link
              >
            </li>
            <li class="mobile-menu-sublist__item" @click="closeMobileMenu">
              <uc-link
                :to="localePath({ name: 'info-page', params: { slug: 'return-policy' } })"
                class="mobile-menu-sublist__link"
                >{{ $t('Возврат и обмен') }}</uc-link
              >
            </li>
            <li class="mobile-menu-sublist__item" @click="closeMobileMenu">
              <uc-link
                :to="localePath({ name: 'info-page', params: { slug: 'service-warranty' } })"
                class="mobile-menu-sublist__link"
                >{{ $t('Сервис и гарантия') }}</uc-link
              >
            </li>
            <li class="mobile-menu-sublist__item" @click="closeMobileMenu">
              <uc-link
                :to="localePath({ name: 'info-page', params: { slug: 'bonus-rules' } })"
                class="mobile-menu-sublist__link"
                >{{ $t('МА Кешбэк') }}</uc-link
              >
            </li>
            <li class="mobile-menu-sublist__item" @click="closeMobileMenu">
              <uc-link
                :to="localePath({ name: 'info-page', params: { slug: 'subscription' } })"
                class="mobile-menu-sublist__link"
                >{{ $t('МА Підписка') }}</uc-link
              >
            </li>
            <li class="mobile-menu-sublist__item" @click="closeMobileMenu">
              <uc-link :to="localePath({ name: 'promotion-page' })" class="mobile-menu-sublist__link">{{
                $t('Акции и распродажи')
              }}</uc-link>
            </li>
            <li class="mobile-menu-sublist__item" @click="closeMobileMenu">
              <uc-link :to="localePath({ name: 'pakunok-malyuka-page' })" class="mobile-menu-sublist__link">{{
                $t('Пакунок малюка')
              }}</uc-link>
            </li>
            <li class="mobile-menu-sublist__item" @click="closeMobileMenu">
              <uc-link
                :to="localePath({ name: 'info-page', params: { slug: 'gift-sertificate' } })"
                class="mobile-menu-sublist__link"
              >
                {{ $t('Подарочные сертификаты') }}
              </uc-link>
            </li>
            <li class="mobile-menu-sublist__item" @click="closeMobileMenu">
              <uc-link :to="localePath({ name: 'blog-page' })" class="mobile-menu-sublist__link">{{
                $t('Блог')
              }}</uc-link>
            </li>
            <li class="mobile-menu-sublist__item" @click="closeMobileMenu">
              <uc-link
                :to="localePath({ name: 'info-page', params: { slug: 'purchase-terms' } })"
                class="mobile-menu-sublist__link"
              >
                {{ $t('Условия покупки') }}
              </uc-link>
            </li>
            <li class="mobile-menu-sublist__item" @click="closeMobileMenu">
              <uc-link
                :to="localePath({ name: 'info-page', params: { slug: 'privacy-policy' } })"
                class="mobile-menu-sublist__link"
              >
                {{ $t('Политика конфиденциальности') }}
              </uc-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div :class="{ block: mobileMenu }" class="mobile-menu-overlay" />
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import vClickOutside from 'v-click-outside';

import LanguageSwitcher from '~/src/components/LanguageSwitcher.vue';
import MobileOutlet from '~/src/components/navigation/MobileOutlet.vue';

import SortableMixin from '~/src/mixins/Sortable.mixin.js';
import TranslatorMixin from '~/src/mixins/Translator.mixin.js';
import { RouterMixin } from 'u-mixins';
import NavigationRouteMixin from '~/src/components/navigation/mixin/NavigationRoute.mixin.js';

const TABLET_SCREEN = 768;

export default {
  directives: {
    clickOutside: vClickOutside.directive
  },
  components: {
    MobileOutlet,
    LanguageSwitcher
  },
  mixins: [SortableMixin, TranslatorMixin, RouterMixin, NavigationRouteMixin],
  data() {
    return {
      mobileMenu: false,
      categoryTitle: '',
      subCategoryTitle: '',
      folderCategory: '',
      subCategories: null,
      isSubCategories: false,
      filteredSubCategories: null,
      isFilteredSubCategories: false,
      brandVisibility: false,
      gender: null,
      screenWidth: '',
      CUSTOM_FILTERS_AGES: [
        { title: '0 - 12 мес', url: { filters: [{ topic: 'age', filters: ['0-6-mes', '6-12-mes'] }] } },
        {
          title: '12 - 36 мес',
          url: { filters: [{ topic: 'age', filters: ['12-24-mes', '2-3-goda'] }] }
        },
        { title: '3 - 8 лет', url: { filters: [{ topic: 'age', filters: ['3-8-let'] }] } },
        { title: '9 - 12 лет', url: { filters: [{ topic: 'age', filters: ['9-12-let'] }] } },
        { title: '13 - 16 лет', url: { filters: [{ topic: 'age', filters: ['13-16-let'] }] } }
      ],
      TOPIC_SLUG_AGE: 'age',
      TOPIC_SLUG_BRAND: 'brand'
    };
  },
  computed: {
    ...mapGetters({
      menuCategories: 'catalog/visibleCategories'
    }),
    TABLET_SCREEN: () => TABLET_SCREEN,
    isBrandsShow() {
      return process.env.BRANDS_SHOW;
    },
    menuBrandList() {
      return (this.$store.state.catalog.brandList || []).filter((brand) => brand.inMenu);
    }
  },
  watch: {
    mobileMenu(isOpen) {
      this.$eventBus.$emit('general:body:hold-scroll', isOpen);
      if (isOpen) {
        this.toMenuTop();
      }
    }
  },
  mounted() {
    this.screenWidth = global.innerWidth;
    global.addEventListener('resize', this.handleResize);
    this.$eventBus.$on('menu:mobile:display', this.displayMobileMenu);
    this.$eventBus.$on('menu:mobile:hide', this.closeMobileMenu);
  },
  beforeDestroy() {
    global.removeEventListener('resize', this.handleResize);
    this.$eventBus.$off('menu:mobile:display', this.displayMobileMenu);
    this.$eventBus.$off('menu:mobile:hide', this.closeMobileMenu);
    this.$eventBus.$emit('general:body:hold-scroll', false);
  },
  methods: {
    toggleMobileMenu() {
      this.mobileMenu = !this.mobileMenu;
    },
    closeMobileMenu() {
      this.mobileMenu = false;
    },
    displayMobileMenu() {
      this.mobileMenu = true;
    },
    displayDialog(tab) {
      this.$eventBus.$emit('modals:register:open', tab);
    },
    handleResize() {
      this.screenWidth = global.innerWidth;
    },
    showLoginModal() {
      this.mobileMenu = false;
      this.$eventBus.$emit('modals:register:open', 'login');
    },
    toProfile() {
      this.mobileMenu = false;
      this.moveToByName('profile-page');
    },
    setCategory(category) {
      this.folderCategory = category;
      this.categoryTitle = this.folderCategory.title;
      this.isSubCategories = true;
      this.gender = null;

      if (category.slug === 'odezhda' || category.slug === 'obuv') {
        const customChildList = [
          {
            title: 'Бренды',
            slug: 'brand',
            childList: this.getFiltersList(this.TOPIC_SLUG_BRAND)
          },
          {
            title: 'Для мальчиков',
            childList: category.childList,
            gender: '1'
          },
          {
            title: 'Для девочек',
            childList: category.childList,
            gender: '2'
          },
          {
            title: 'Возраст',
            slug: 'age',
            childList: this.$utils.cloneObj(this.CUSTOM_FILTERS_AGES)
          }
        ];

        this.subCategories = this.$utils.cloneObj(customChildList);
      }

      if (category.slug !== 'odezhda' && category.slug !== 'obuv') {
        this.subCategories = category.childList;

        const categoryList = this.subCategories.map((value) => ({
          title: value.title,
          url: { category: [value.slug] }
        }));

        this.subCategories = categoryList;
      }

      this.toMenuTop();
    },
    toCategory() {
      this.isSubCategories = false;
      this.toMenuTop();
    },
    setSubCategory(category) {
      this.categoryTitle = this.folderCategory.title;
      this.subCategoryTitle = category.title;
      this.isFilteredSubCategories = true;
      this.filteredSubCategories = null;
      this.gender = null;

      if (category.gender) {
        this.gender = [{ topic: 'gender', filters: [category.gender] }];
      }

      if (category.slug !== 'age' && category.slug !== 'brand') {
        this.filteredSubCategories = category.childList.map((value) => ({
          title: value.title,
          url: { category: [value.slug] }
        }));
      } else {
        this.filteredSubCategories = category.childList.length && category.childList;
      }

      // Disable platya-i-yubki for boys subcategory
      if (this.folderCategory.slug === 'odezhda' && category.gender === '1') {
        this.filteredSubCategories = this.filteredSubCategories.filter(
          (item) => !item.url.category.includes('platya-i-yubki')
        );
      }

      this.toMenuTop();
    },
    toSubCategory() {
      this.isFilteredSubCategories = false;
      this.toMenuTop();
    },
    toBrands() {
      this.brandVisibility = true;
      this.toMenuTop();
    },
    outBrands() {
      this.brandVisibility = false;
      this.toMenuTop();
    },
    getFiltersList(topicSlug) {
      try {
        const topic = this.folderCategory.topicList.find((value) => value.slug === topicSlug) || null;

        return ((topic && topic.filterList) || []).map((value) => ({
          title: this.getTranslationBySlug(value.slug),
          url: { filters: [{ topic: topic.slug, filters: [value.slug] }] }
        }));
      } catch (e) {
        if (this.$sentry) {
          this.$sentry.captureException(e);
        }
      }
    },
    toMenuTop() {
      const el = document.querySelector('.mobile-menu-body');
      if (el) {
        el.scrollTo(0, 0);
      }
    }
  }
};
</script>
