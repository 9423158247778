// @deprecated
// @todo: replace to u-mixins
export default {
  computed: {
    translations() {
      return this.$store.state.catalog.categoryTranslation;
    }
  },
  methods: {
    getTranslationBySlug(slug, onlyTitle = true) {
      let translation = onlyTitle ? '' : {};

      try {
        if (this.$utils.isObject(this.translations) && slug in this.translations) {
          translation = onlyTitle ? this.translations[slug].title : this.translations[slug];
        }
      } catch (e) {
        console.error('Translator mixin error', e);
      }

      return translation;
    }
  }
};
