<template>
  <div v-if="visibility" class="header-banner">
    <uc-link
      class="header-banner__link"
      :to="link"
      :style="backgroundColor"
      @click.native="$gtm.setPromotionAction(gtmPayload, $gtm.constructor.PROMOTION_SELECT_KEY)"
    >
      <uc-content-image class="header-banner__mobile" height="40" width="320" :src="imgMobileUrl" alt="header-banner" />
      <uc-content-image class="header-banner__desktop" :src="imgDesktopUrl" alt="header-banner" />
    </uc-link>
    <button type="button" class="header-banner__close" @click="hide()">
      <uc-content-image src="/assets/svg/close-circle.svg" alt="close" />
    </button>
  </div>
</template>

<script>
export default {
  name: 'HeaderBanner',
  data() {
    return {
      visibility: false,
      content: {},
      link: '',
      color: '',
      imgDesktopUrl: '',
      imgMobileUrl: ''
    };
  },
  computed: {
    backgroundColor() {
      return `background-color: ${this.color}`;
    },
    gtmPayload() {
      if (!this.content) {
        return null;
      }
      return {
        promotion_id: this.content.promotion_id,
        promotion_name: this.content.promotion_name,
        creative_slot: this.content.creative_slot
      };
    }
  },
  watch: {
    '$i18n.locale': {
      handler() {
        this.getBannerInfo();
      }
    }
  },
  mounted() {
    this.getBannerInfo();
  },
  methods: {
    async getBannerInfo() {
      try {
        const { data } = await this.$api.ContentService.getJSONContentBlockBySlug('header-banner', this.$i18n.locale);
        const { contentFormatted = {} } = data;
        const { link = '/', color = '#fff', imgDesktopUrl = null, imgMobileUrl = null } = contentFormatted;

        this.content = contentFormatted;
        this.link = link;
        this.color = color;
        this.imgDesktopUrl = imgDesktopUrl;
        this.imgMobileUrl = imgMobileUrl;

        if ((this.imgDesktopUrl || this.imgMobileUrl) && this.isTimeToShow()) {
          this.visibility = true;
        }
        this.$gtm.setPromotionAction(this.gtmPayload, this.$gtm.constructor.PROMOTION_VIEW_KEY);
      } catch (e) {
        if (this.$sentry && e !== 'No data') {
          this.$sentry.captureException(e);
        }
      }
    },
    hide() {
      this.visibility = false;
      const time = new Date().getTime();
      this.$lstore.setStoreByName(this.$lstore.getAnonStoreName('headerBanner'), time) || [];
    },
    isTimeToShow() {
      const creationTime = this.$lstore.getStore('headerBanner');
      const currentTime = new Date().getTime().toString();
      const expirationDuration = 1000 * 60 * 60 * 24 * 7;

      return currentTime - creationTime > expirationDuration;
    }
  }
};
</script>

<style lang="scss" src="~/assets/scss/header-banner/_header-banner.scss" />
