<template>
  <div>
    <svg-sprite />
    <noscript inline-template>
      <div class="noscript-main" data-nosnippet>
        <h3>{{ $t('noscriptDefaultTitle') }}</h3>
        <p>{{ $t('noscriptDefaultText') }}</p>
      </div>
    </noscript>

    <app-header-banner />

    <app-header />
    <div class="overlay" />
    <nuxt />
    <app-footer />
    <client-only>
      <notifications
        group="default"
        :duration="12000"
        :width="500"
        animation-name="v-fade-left"
        position="top center"
        class="notifications"
      >
        <template slot="body" slot-scope="props">
          <div class="vue-notification" :class="`vue-notification-${props.item.type}`">
            <div class="vue-notification-icon">
              <span class="icon" :class="`icon-${props.item.type}`"></span>
            </div>
            <div class="vue-notification-content">
              <div class="vue-notification-title">{{ $t(`${props.item.title}`) }}</div>
              <div v-if="props.item.text" class="vue-notification-text">{{ $t(`${props.item.text}`) }}</div>
            </div>
            <div class="vue-notification-close" @click="props.close">
              <p :class="`vue-notification-close-${props.item.type}`">{{ $t('Закрыть') }}</p>
            </div>
          </div>
        </template>
      </notifications>
    </client-only>
    <client-only>
      <app-modals :inner-navigated="innerNavigated" />
    </client-only>
  </div>
</template>

<script>
import rulesExtender from 'u-utils/module/rules.js';

import appHeaderBanner from '~/src/components/general/appHeaderBanner.vue';
import appHeader from '~/src/components/general/appHeader.vue';
import appFooter from '~/src/components/general/appFooter.vue';
import svgSprite from '~/src/components/page-blocks/Svg-sprite.vue';
import appModals from '~/src/components/general/appModals.vue';
import BonusProgramMemberMixin from '~/src/mixins/BonusProgramMember';
import metaRu from '~/src/meta/ru/MainMeta.json';
import metaUa from '~/src/meta/ua/MainMeta.json';
import SearchRequestMicroMarkup from '~/src/micro-markup/SearchRequest.json';
import OrganizationMicroMarkup from '~/src/micro-markup/Organization.json';

export default {
  components: {
    appHeaderBanner,
    appHeader,
    appFooter,
    svgSprite,
    appModals
  },
  mixins: [BonusProgramMemberMixin],
  provide() {
    const LAYOUT_PROVIDES = {};
    Object.defineProperty(LAYOUT_PROVIDES, 'SCREEN_SIZES', {
      enumerable: true,
      get: () => this.SCREEN_SIZES
    });
    return { LAYOUT_PROVIDES };
  },
  middleware: 'DataLayerPath',
  data() {
    return {
      isNoScroll: false,
      innerNavigated: false,
      SCREEN_WIDTH: global.innerWidth
    };
  },
  head() {
    const meta = this.$metaService.getImportedMetaByLang(metaRu, metaUa, this.$i18n.locale);
    const i18nSeo = this.i18nSeo();
    return {
      htmlAttrs: i18nSeo.htmlAttrs,
      bodyAttrs: {
        class: this.isNoScroll ? 'no-scroll' : ''
      },
      ...meta,
      link: [...i18nSeo.link, ...meta.link],
      script: [
        {
          json: SearchRequestMicroMarkup[this.$i18n.locale],
          type: 'application/ld+json'
        },
        {
          json: OrganizationMicroMarkup[this.$i18n.locale],
          type: 'application/ld+json'
        }
      ]
    };
  },
  computed: {
    SCREEN_SIZES() {
      const MOBILE_SCREEN = 768;
      const TABLET_SCREEN = 1024;
      return {
        IS_SCREEN_MOBILE: this.SCREEN_WIDTH < MOBILE_SCREEN,
        IS_SCREEN_TABLET_AND_LESS: this.SCREEN_WIDTH < TABLET_SCREEN
      };
    }
  },
  mounted() {
    this.navigateWatcher = this.$watch('$route.path', this.routePathHandler);
    this.$eventBus.$on('general:body:hold-scroll', this.setIsNoScroll);
    global.addEventListener('resize', this.handleWindowResize);
    rulesExtender(this);
  },
  beforeDestroy() {
    this.$eventBus.$off('general:body:hold-scroll', this.setIsNoScroll);
    global.removeEventListener('resize', this.handleWindowResize);
  },
  methods: {
    setIsNoScroll(bool) {
      if (typeof bool === 'boolean') {
        this.isNoScroll = bool;
      }
    },
    routePathHandler() {
      this.innerNavigated = true;
      this.navigateWatcher();
    },
    handleWindowResize() {
      this.SCREEN_WIDTH = global.innerWidth;
    }
  }
};
</script>
