// @deprecated
// @todo: replace to u-mixins
export default {
  methods: {
    sortByOrdering(arrayOfObjects) {
      arrayOfObjects.sort((a, b) => {
        return a.ordering > b.ordering ? 1 : -1;
      });

      return arrayOfObjects;
    }
  }
};
