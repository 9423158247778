<template>
  <uc-modal v-model="isModalVisible">
    <div class="subscription-modal subscription-modal-background exists">
      <div class="modal-text__exists">{{ $t('Вы уже') }}<br />{{ $t('подписаны') }}!</div>
    </div>
  </uc-modal>
</template>

<script>
export default {
  name: 'UserExistsModal',
  data() {
    return {
      isModalVisible: false
    };
  },
  watch: {
    isModalVisible(isOpen) {
      this.$eventBus.$emit('general:body:hold-scroll', isOpen);
    }
  },
  mounted() {
    this.$eventBus.$on('user-exists:modal:show', this.show);
  },
  beforeDestroy() {
    this.$eventBus.$emit('general:body:hold-scroll', false);
    this.$eventBus.$off('user-exists:modal:show', this.show);
  },
  methods: {
    close() {
      this.isModalVisible = false;
    },
    show() {
      this.isModalVisible = true;
    }
  }
};
</script>

<style lang="scss" src="~/assets/scss/modals/_subscription-modal.scss" />
