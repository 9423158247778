<template>
  <div class="nav-line">
    <div
      v-for="(group, index) in searchGroupsWithoutFolder"
      :key="`nav-line-item-${group.slug}-${index}`"
      class="nav-line__item"
    >
      <uc-link
        v-if="!isCurrentPage(group)"
        class="nav-line__item-link"
        :to="getLink(group)"
        :aria-label="group.title"
        >{{ group.title }}</uc-link
      >
      <a v-else class="nav-line__item-link" :href="getLink(group)" :aria-label="group.title">{{ group.title }}</a>
    </div>
    <div
      v-click-outside="hideWidgetClick"
      class="nav-line__more"
      @mouseover="displayWidgetMouse()"
      @mouseleave="hideWidgetMouse()"
      @click="displayWidgetClick()"
    >
      {{ $t('Ещё') }}
      <img
        class="nav-line__more-img"
        src="https://static.ma.com.ua/ma/svg/nav-chevron.svg"
        width="16"
        height="16"
        alt="chevron"
      />
      <div v-if="isVisible" class="nav-line__more-block">
        <div class="nav-line__more-wrapper">
          <div
            v-for="(group, index) in searchGroupsWithoutFolder"
            :key="`nav-line-more-item-${group.slug}-${index}`"
            class="line-more__item"
          >
            <uc-link
              v-if="!isCurrentPage(group)"
              class="line-more__item-link"
              :to="getLink(group)"
              :aria-label="group.title"
              >{{ group.title }}</uc-link
            >
            <a v-else class="line-more__item-link" :href="getLink(group)" :aria-label="group.title">{{
              group.title
            }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SearchGroupsMixin from '~/src/components/catalog/mixin/SearchGroups.mixin.js';
import vClickOutside from 'v-click-outside';
import ScreenWidthMixin from '~/src/mixins/ScreenWidth';

export default {
  directives: {
    clickOutside: vClickOutside.directive
  },
  mixins: [SearchGroupsMixin, ScreenWidthMixin],
  data() {
    return {
      isVisible: false
    };
  },
  watch: {
    '$route.path': {
      handler() {
        this.hideWidget();
      }
    }
  },
  methods: {
    isCurrentPage(group) {
      const { folderCategory } = group;

      return folderCategory && folderCategory !== '' && !!(this.$route.fullPath.indexOf(folderCategory) + 1);
    },
    displayWidgetMouse() {
      if (!this.isScreenDesktop) {
        return;
      }

      this.isVisible = true;
    },
    displayWidgetClick() {
      if (this.isScreenDesktop) {
        return;
      }

      this.isVisible = true;
    },
    hideWidget() {
      this.isVisible = false;
    },
    hideWidgetMouse() {
      if (!this.isScreenDesktop) {
        return;
      }

      this.isVisible = false;
    },
    hideWidgetClick() {
      if (this.isScreenDesktop) {
        return;
      }

      this.isVisible = false;
    }
  }
};
</script>

<style lang="scss" src="~/assets/scss/navigation-line/_navigation-line.scss" />
