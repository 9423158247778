<template>
  <section class="dropdowns-menu">
    <div class="dropdowns-menu-wrap">
      <div class="dropdowns-menu__col-gender">
        <category-column
          v-if="category.slug === CATEGORY_CLOTHES || category.slug === CATEGORY_SHOES"
          title="Для мальчиков"
          :folder-category="category.slug"
          :child-list="CUSTOM_FILTERS_AGES"
          :child-gender="1"
          link-class="menu-list__text_capitalize"
        />
        <category-column
          v-if="category.slug === CATEGORY_CLOTHES || category.slug === CATEGORY_SHOES"
          title="Для девочек"
          :folder-category="category.slug"
          :child-list="CUSTOM_FILTERS_AGES"
          :child-gender="2"
          link-class="menu-list__text_capitalize"
        />
      </div>
      <div class="dropdowns-menu__col-gender">
        <category-column title="Категории" :folder-category="category.slug" :child-list="getChildCategoryList()" />
      </div>
      <div class="dropdowns-menu__col-gender">
        <category-column title="Возраст" :folder-category="category.slug" />
        <category-column
          v-if="category.slug === CATEGORY_TOYS"
          title="Возраст"
          :folder-category="category.slug"
          :child-list="getFiltersList(TOPIC_SLUG_AGE)"
          link-class="menu-list__text_capitalize"
        />
      </div>
      <div class="dropdowns-menu__col-gender">
        <category-column
          title="Бренды"
          :folder-category="category.slug"
          :child-list="getFiltersList(TOPIC_SLUG_BRAND)"
          link-class="menu-list__text_capitalize"
        />
      </div>

      <app-navigation-banner v-if="Object.keys(slide).length" :category="category.slug" :slide="slide" />
    </div>
  </section>
</template>

<script>
import CategoryColumn from '~/src/components/navigation/CategoryColumn.vue';
import TranslatorMixin from '~/src/mixins/Translator.mixin.js';
import appNavigationBanner from '~/src/components/general/navigation/appNavigationBanner.vue';

//категория, возраст, бренд, пол(для обуви и одежды)
export default {
  components: {
    CategoryColumn,
    appNavigationBanner
  },
  mixins: [TranslatorMixin],
  props: {
    category: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      CATEGORY_CLOTHES: 'odezhda',
      CATEGORY_SHOES: 'obuv',
      CATEGORY_TOYS: 'igrushki',
      TOPIC_SLUG_AGE: 'age',
      TOPIC_SLUG_BRAND: 'brand',
      TOPIC_SLUG_GENDER: 'gender',
      CUSTOM_FILTERS_AGES: [
        { title: '0 - 12 мес', url: { filters: [{ topic: 'age', filters: ['0-6-mes', '6-12-mes'] }] } },
        {
          title: '12 - 36 мес',
          url: { filters: [{ topic: 'age', filters: ['12-24-mes', '2-3-goda'] }] }
        },
        { title: '3 - 8 лет', url: { filters: [{ topic: 'age', filters: ['3-8-let'] }] } },
        { title: '9 - 12 лет', url: { filters: [{ topic: 'age', filters: ['9-12-let'] }] } },
        { title: '13 - 16 лет', url: { filters: [{ topic: 'age', filters: ['13-16-let'] }] } }
      ]
    };
  },
  computed: {
    slider() {
      return this.$store.state.catalog.navSlider;
    },
    slide() {
      if (!(this.slider.activeSliderItem && this.slider.activeSliderItem.length)) {
        return {};
      }
      const item = this.slider.activeSliderItem.find((item) => item.title === this.category.slug);

      return item || {};
    }
  },
  methods: {
    getChildCategoryList() {
      const categoryList = this.category.childList.map((value) => ({
        title: value.title,
        url: { category: [value.slug] }
      }));

      return categoryList;
    },
    getFiltersList(topicSlug) {
      try {
        const topic = this.category.topicList.find((value) => value.slug === topicSlug) || null;

        return ((topic && topic.filterList) || []).map((value) => ({
          title: this.getTranslationBySlug(value.slug),
          url: { filters: [{ topic: topic.slug, filters: [value.slug] }] }
        }));
      } catch (e) {
        if (this.$sentry) {
          this.$sentry.captureException(e);
        }
      }
    }
  }
};
</script>
