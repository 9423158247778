<template>
  <header>
    <div class="header" :class="{ 'fixed-header': stickyHeader }">
      <div class="container tablet-container">
        <div class="row no-gutters">
          <div class="header-wrapper">
            <app-mobile-menu />
            <uc-link title="Ma" :to="localePath({ name: 'main' })" aria-label="Ma" class="site-logo">
              <img src="https://static.ma.com.ua/ma/svg/ma-logo.svg" width="171" height="48" alt="main-logo" />
            </uc-link>
            <div class="header-menu">
              <client-info-widget class="header-menu__item" />
              <wish-list-counter-widget class="header-menu__item header-menu__item-wish-list" />
              <basket-widget class="header-menu__item" />
              <authorisation-widget class="header-menu__item" />
              <language-switcher class="header-menu__item" />
              <div class="header-menu__item">
                <a href="tel:0443230906" class="menu-item__phone" title="(044) 323-09-06" aria-label="(044) 323-09-06"
                  >(044) 323-09-06
                  <img
                    class="item-phone__chevron"
                    src="https://static.ma.com.ua/ma/svg/nav-chevron.svg"
                    alt="chevron"
                    height="15"
                    width="15"
                  />
                </a>
                <div class="call-center">
                  <div class="call-center__head">{{ $t('График работы') }}</div>
                  <div class="call-center__text">{{ $t('пн-вс: с') }} 09:00 {{ $t('до') }} 20:00</div>
                </div>
              </div>
            </div>
            <div class="header-navigation">
              <app-navigation :is-visible="navVisibility" />
              <app-navigation-line />
              <quick-search />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="stickyHeader" class="fixed-header-dummy" />
  </header>
</template>

<script>
import ClientInfoWidget from '~/src/components/general/widget/ClientInfoWidget.vue';
import BasketWidget from '~/src/components/basket/BasketWidget.vue';
import WishListCounterWidget from '~/src/components/wish-list/WishListCounterWidget.vue';
import AuthorisationWidget from '~/src/components/page-blocks/AuthorisationWidget.vue';
import appNavigation from '~/src/components/general/appNavigation.vue';
import appNavigationLine from '~/src/components/general/navigation/appNavigationLine.vue';
import QuickSearch from '~/src/components/quick-search/QuickSearch.vue';
import appMobileMenu from '~/src/components/general/appMobileMenu.vue';
import ScreenWidthMixin from '~/src/mixins/ScreenWidth';
import LanguageSwitcher from '~/src/components/LanguageSwitcher.vue';

export default {
  components: {
    ClientInfoWidget,
    BasketWidget,
    WishListCounterWidget,
    AuthorisationWidget,
    appNavigation,
    appNavigationLine,
    QuickSearch,
    appMobileMenu,
    LanguageSwitcher
  },
  mixins: [ScreenWidthMixin],
  data() {
    return {
      navVisibility: false,
      stickyHeader: false,
      displayTop: true
    };
  },
  computed: {
    headerHeight() {
      if (this.isScreenSmallDesktop) {
        return 136;
      } else if (this.isScreenTablet) {
        return 92;
      } else if (this.isScreenMobile) {
        return 116;
      } else {
        return 148;
      }
    }
  },
  watch: {
    '$route.path': {
      handler(val) {
        this.navVisibility =
          (val === '/' && this.stickyHeader === false) || (val === '/ua' && this.stickyHeader === false);
      }
    }
  },
  mounted() {
    this.navVisibility =
      (this.$route.path === '/' && this.stickyHeader === false) ||
      (this.$route.path === '/ua' && this.stickyHeader === false);
    global.addEventListener('scroll', this.handleScroll);
    this.$eventBus.$on('app:unfixed:header', this.unfixTop);
    this.$eventBus.$on('app:fixed:header', this.fixTop);
  },
  beforeDestroy() {
    global.removeEventListener('scroll', this.handleScroll);
    this.$eventBus.$off('app:unfixed:header', this.unfixTop);
    this.$eventBus.$off('app:fixed:header', this.fixTop);
  },
  methods: {
    handleScroll() {
      this.stickyHeader = global.scrollY >= this.headerHeight && this.displayTop;
      this.navVisibility =
        (this.$route.path === '/' && !this.stickyHeader) || (this.$route.path === '/ua' && !this.stickyHeader);
    },
    unfixTop() {
      this.displayTop = false;
    },
    fixTop() {
      this.displayTop = true;
    }
  }
};
</script>

<style lang="scss" src="~/assets/scss/header/_header.scss" />
