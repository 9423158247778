<template>
  <section v-click-outside="closeSearchResult" class="quick-search__block">
    <form ref="search" class="quick-search-form" @submit.prevent="submit()">
      <input
        ref="searchField"
        v-model="search"
        class="quick-search-form__field"
        type="text"
        :placeholder="$t(placeholder)"
        aria-label="Поиск"
        @input="throttledSearch($event)"
        @click="focusHandler"
        @keyup.enter="enterHandler()"
      />
      <div
        :class="{ 'display-none': !search.length }"
        class="quick-search-icon quick-search-icon__clear"
        @click="resetQuickSearch()"
      >
        <img src="https://static.ma.com.ua/ma/svg/menu/mobile-nav-close.svg" alt="search-clear" />
      </div>
      <div
        ref="voiceTrigger"
        :class="{ 'display-none': search.length }"
        class="quick-search-icon quick-search-icon__voice"
        @click.prevent="listenStart()"
      >
        <img src="https://static.ma.com.ua/chicco-assets/svg/header/mic.svg" alt="search-voice" />
      </div>
      <div :class="{ 'display-none': search.length }" class="quick-search-icon quick-search-icon__search">
        <img src="https://static.ma.com.ua/ma/svg/search-loop.svg" alt="search" />
      </div>
      <div :class="{ 'is-opened': isDisplaySearchResult }" class="quick-search-result">
        <div ref="search_list" class="quick-search-result-wrapper">
          <search-item
            v-for="product in products"
            :key="`quick-search-search-item-${product.id}`"
            :class="{ invisible: loader }"
            :product="product"
            @click="closeSearchResult"
          />

          <div :class="{ 'mobile-search-status-text': loader }">
            <div v-if="!loader && !products.length" class="quick-search-status-text">
              {{ $t('Ничего не найдено') }}...
            </div>
            <loader v-if="loader" />
          </div>
        </div>

        <div v-if="!loader && categories.length" class="quick-search-result_category">
          <div class="category-title">{{ $t('Категории') }}</div>
          <div class="category-links">
            <uc-link
              v-for="category in categories"
              :key="`quick-search-search-category-${category.slug}`"
              :to="
                localePath({
                  name: 'product-catalog-page',
                  params: getCategoryUrl(category)
                })
              "
              class="link"
            >
              <span>{{ category.title }}</span>
            </uc-link>
          </div>
        </div>

        <div v-if="products.length" class="quick-search-result_btn">
          <uc-link :to="localePath({ name: 'search-result-page', query: { q: search } })" class="link">
            <button type="submit" @click="clickHandler()">
              {{ $t('Все результаты') }}&nbsp;<span v-if="!loader">({{ total }})</span>
            </button>
          </uc-link>
        </div>
      </div>
    </form>
  </section>
</template>

<script>
import vClickOutside from 'v-click-outside';
import QuickSearchMixin from '~/src/components/quick-search/mixin/QuickSearch.mixin.js';
import { RouterMixin } from 'u-mixins';

export default {
  directives: {
    clickOutside: vClickOutside.directive
  },
  mixins: [QuickSearchMixin, RouterMixin],
  methods: {
    searchButton() {
      if (this.checkValidLength()) {
        this.clickHandler();
      }
    }
  }
};
</script>

<style scoped>
.loader {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
</style>
