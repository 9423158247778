<template>
  <section>
    <auth-modal />
    <birthday-bonus-modal />
    <client-only>
      <basket-notification />
    </client-only>
    <move-to-wish-list />
    <add-to-wish-list />
    <subscription-modal v-if="false && innerNavigated" />
    <subscribe-thanks-modal />
    <user-exists-modal />
    <gifts-modal />
  </section>
</template>

<script>
import AuthModal from '~/src/components/auth/AuthModal.vue';
import BirthdayBonusModal from '~/src/components/bonus/BirthdayBonusModal.vue';
import BasketNotification from '~/src/components/basket/BasketNotification.vue';
import MoveToWishList from '~/src/components/wish-list/modal/MoveToWishList.vue';
import AddToWishList from 'u-wishlist/lib/components/modal/catalog/AddToWishList.vue';
import SubscriptionModal from '~/src/components/subscription/SubscriptionModal.vue';
import SubscribeThanksModal from '~/src/components/subscription/SubscribeThanksModal.vue';
import UserExistsModal from '~/src/components/subscription/UserExistsModal.vue';
import GiftsModal from '~/src/components/basket/modal/GiftsModal.vue';

export default {
  components: {
    AuthModal,
    BirthdayBonusModal,
    BasketNotification,
    MoveToWishList,
    AddToWishList,
    SubscriptionModal,
    SubscribeThanksModal,
    UserExistsModal,
    GiftsModal
  },
  props: {
    innerNavigated: {
      type: Boolean,
      required: true
    }
  }
};
</script>
