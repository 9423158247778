<template>
  <div>
    <div v-if="isBonusProgramMember && profile && profile.isMember" class="widget-wrapper__bonus">
      <div class="wrapper-bonus__text wrapper-bonus__first">
        {{ $t('Ваш статус') }}: <span class="bonus-text__status">{{ profile.levelName }}</span>
      </div>
      <div class="wrapper-bonus__text">{{ $t('Доступно кешбэка') }}:</div>
      <div class="wrapper-bonus__amount">
        {{ profile.activeAmount | currency }}
      </div>
    </div>
    <loader v-if="!profile" class="wrapper-bonus__loader" />
  </div>
</template>

<script>
import BonusProgramMemberMixin from '~/src/mixins/BonusProgramMember';
import Loader from '~/src/components/page-blocks/Loader.vue';

export default {
  components: { Loader },
  mixins: [BonusProgramMemberMixin],
  computed: {
    profile() {
      return this.$store.state.bonus.profile;
    }
  },
  created() {
    this.$bonus.getBonusProfileIfNotExist();
  }
};
</script>
