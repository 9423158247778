import * as catalogMutation from 'u-catalog/lib/store/mutation-types.js';
import SearchItem from '~/src/components/quick-search/SearchItem.vue';
import loader from '~/src/components/page-blocks/Loader.vue';
import ScreenWidth from '~/src/mixins/ScreenWidth';
import NavigationRouteMixin from '~/src/components/navigation/mixin/NavigationRoute.mixin.js';

const MIN_SEARCH_TEXT_LENGTH = 2;

export default {
  components: {
    SearchItem,
    loader
  },
  mixins: [ScreenWidth, NavigationRouteMixin],
  data() {
    return {
      lastRequest: null,
      search: '',
      products: [],
      categories: [],
      intervalId: null,
      isDisplaySearchResult: false,
      loader: false,
      total: 0,
      recognition: null,
      placeholder: 'Поиск'
    };
  },
  watch: {
    search: {
      handler() {
        if (this.search.length > 0) {
          this.throttledSearch();
        } else {
          this.closeSearchResult();
        }
      },
      immidiate: false
    },
    '$route.path': {
      handler() {
        //Reset search input when route is changed
        this.resetQuickSearch();
      },
      deep: false,
      immediate: false
    }
  },
  mounted() {
    this.$eventBus.$on('quick-search:search:hide', this.hideSearch);
    this.$eventBus.$on('quick-search:result:close', this.closeSearchResult);
    this.$eventBus.$on('quick-search:reset-search', this.resetQuickSearch);

    const SpeechRecognition = global.SpeechRecognition || global.webkitSpeechRecognition;
    const voiceTrigger = this.$refs.voiceTrigger;
    if (SpeechRecognition) {
      this.recognition = new SpeechRecognition();
      this.recognition.lang = 'ru-RU';
      this.recognition.addEventListener('result', this.transcriptHandler);
      this.recognition.onspeechend = () => {
        voiceTrigger.classList.remove('voice__active');
        this.placeholder = 'Поиск';
        this.recognition.stop();
      };

      this.recognition.onerror = (event) => {
        if (event.error == 'no-speech') {
          voiceTrigger.classList.remove('voice__active');
          this.placeholder = 'Поиск';
          this.recognition.stop();
        }
      };
    } else {
      this.$refs.voiceTrigger.remove();
    }
  },
  beforeDestroy() {
    this.$eventBus.$off('quick-search:search:hide', this.hideSearch);
    this.$eventBus.$off('quick-search:result:close', this.closeSearchResult);
    this.$eventBus.$off('quick-search:reset-search', this.resetQuickSearch);
  },
  methods: {
    checkValidLength() {
      return this.search.length >= MIN_SEARCH_TEXT_LENGTH;
    },
    throttledSearch(event) {
      if (event && event.target && event.target.value) {
        this.search = event.target.value;
      }
      this.$store.commit(`catalog/${catalogMutation.SET_QUICK_SEARCH_REQUEST}`, this.search);
      const DELAY = 300;

      if (this.intervalId) {
        clearInterval(this.intervalId);
      }

      this.intervalId = setTimeout(() => {
        this.submit();
      }, DELAY);
    },
    submit() {
      if (!this.checkValidLength()) {
        return null;
      }

      if (this.lastRequest === this.search) {
        this.isDisplaySearchResult = true;
        return null;
      }

      this.loader = true;
      this.isDisplaySearchResult = true;
      clearInterval(this.intervalId);
      this.lastRequest = this.search;

      const getProducts = this.$store.dispatch('catalog/getProductByTitle', this.search).then(({ data }) => {
        this.products = data.items;
        this.total = data.pagination.total;
      });

      const getCategories = this.$store.dispatch('catalog/getCategoryByTitle', this.search).then(({ data }) => {
        this.categories = (data?.items && data.items.slice(0, 3)) || [];
      });

      Promise.all([getProducts, getCategories])
        .then(() => {
          this.loader = false;
          this.$store.commit(`catalog/${catalogMutation.SET_QUICK_SEARCH_REQUEST}`, this.search);
        })
        .catch((e) => {
          if (this.$sentry) {
            this.$sentry.captureException(e);
          }
          this.loader = false;
          console.error('Quick Search Error:', e);
        });
    },
    closeSearchResult() {
      if (!this.isDisplaySearchResult) {
        return null;
      }
      this.isDisplaySearchResult = false;
    },
    hideSearch() {
      this.closeSearchResult();
    },
    focusHandler() {
      if (this.search && this.products.length) {
        this.isDisplaySearchResult = true;
      }
    },
    clickHandler() {
      if (this.isScreenMobile) {
        this.$eventBus.$emit('quick-search:mobile-search:close');
      }
      this.closeSearchResult();
    },
    resetQuickSearch() {
      this.search = '';
      this.products = [];
      this.lastRequest = null;
      if (this.$refs.voiceTrigger) {
        this.$refs.voiceTrigger.classList.remove('voice__active');
      }
    },
    listenStart() {
      this.recognition.stop();
      this.placeholder = 'Говорите...';

      if (this.search.length) {
        this.search = '';
      }
      if (this.$refs.voiceTrigger) {
        this.$refs.voiceTrigger.classList.add('voice__active');
      }
      this.recognition.start();
    },
    parseTranscript(e) {
      return Array.from(e.results)
        .map(function (result) {
          return result[0];
        })
        .map(function (result) {
          return result.transcript;
        })
        .join('');
    },
    transcriptHandler(e) {
      const speechOutput = this.parseTranscript(e);
      this.search = speechOutput;
      if (e.results[0].isFinal) {
        this.$refs.voiceTrigger.classList.remove('voice__active');
        this.recognition.stop();
      }
    },
    enterHandler() {
      if (this.search.length < MIN_SEARCH_TEXT_LENGTH) {
        return null;
      }

      if (this.$route.name === `search-result-page___${this.$i18n.locale}` && this.$route.query.q === this.search) {
        this.clickHandler();
      } else {
        this.$router.push(this.localePath({ name: 'search-result-page', query: { q: this.search } }));
      }
    },
    getCategoryUrl(category) {
      return category.isFolder
        ? this.buildParams(category.slug)
        : this.buildParams(category.parentCategory.slug, category.slug);
    }
  }
};
