<template>
  <div
    v-click-outside="hideWidgetClick"
    class="basket-widget"
    @mouseover="displayWidgetMouse()"
    @mouseleave="hideWidgetMouse()"
  >
    <div class="basket-widget__button" @click="basketModel && !basketModel.isEmpty ? toBasket() : displayWidgetClick()">
      <svg class="widget-button__img" :class="{ active: isVisible }">
        <use xlink:href="#cart" />
      </svg>
      <div v-if="count > 0" class="widget-button__counter">
        <span>{{ count }}</span>
      </div>
      <div class="widget-button__text">{{ $t('Корзина') }}</div>
    </div>
    <div :class="{ 'display-none': !isVisible }" class="basket-widget__block">
      <div class="basket-widget__wrapper">
        <basket-widget-list v-if="basketModel && !basketModel.isEmpty" />
        <basket-widget-empty v-else />
      </div>
    </div>
  </div>
</template>

<script>
import vClickOutside from 'v-click-outside';
import BasketWidgetList from '~/src/components/basket/BasketWidgetList.vue';
import BasketWidgetEmpty from '~/src/components/basket/BasketWidgetEmpty.vue';
import ScreenWidthMixin from '~/src/mixins/ScreenWidth';

export default {
  directives: {
    clickOutside: vClickOutside.directive
  },
  components: { BasketWidgetList, BasketWidgetEmpty },
  mixins: [ScreenWidthMixin],
  data() {
    return {
      isVisible: false,
      basketModel: null,
      count: 0
    };
  },
  watch: {
    '$store.state.basket.basket': {
      handler() {
        this.basketModel = this.$basket.getBasket();
        this.count = (this.basketModel && this.basketModel.totalCount) || 0;
      },
      deep: true
    },
    '$route.path': {
      handler() {
        this.hideWidget();
      }
    }
  },
  created() {
    if (process.client) {
      this.$basket.updateBasketStore();
    }
  },
  mounted() {
    global.addEventListener('storage', () => {
      this.$basket.updateBasketStore();
    });
    if (this.$store.state.basket.basket) {
      this.$basket.checkBasket();
    }
  },
  beforeDestroy() {
    global.removeEventListener('storage', (e) => {
      this.$basket.updateBasket(e, this.$store.state.user.auth);
    });
  },
  methods: {
    displayWidgetMouse() {
      if (!this.isScreenDesktop) {
        return;
      }

      this.isVisible = true;
    },
    displayWidgetClick() {
      if (this.isScreenDesktop) {
        return;
      }

      this.isVisible = true;
    },
    hideWidget() {
      this.isVisible = false;
    },
    hideWidgetMouse() {
      if (!this.isScreenDesktop) {
        return;
      }

      this.isVisible = false;
    },
    hideWidgetClick() {
      if (this.isScreenDesktop) {
        return;
      }

      this.isVisible = false;
    },
    toBasket() {
      if (this.$route.name !== `basket-page___${this.$i18n.locale}`) {
        this.$router.push(this.localePath({ name: 'basket-page' }));
      }

      this.hideWidget();
    }
  }
};
</script>

<style lang="scss" src="~/assets/scss/basket-widget/_basket-widget.scss" />
