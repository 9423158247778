export default {
  computed: {
    currentSearchParams() {
      return this.$store.getters['catalog/currentSearchParams'];
    },
    searchGroups() {
      try {
        const { folderCategory, filters = {} } = this.currentSearchParams;
        const { category = [] } = filters;

        return this.$store.getters['catalog/searchGroups'].filter((group) => {
          if (this.$route.name === `search-result-page___${this.$i18n.locale}`) {
            return false;
          } else if (category.length === 1 && group.categoryRefs.includes(category[0])) {
            return true;
          } else if (
            group.categoryRefs.includes(folderCategory) &&
            category.length === 1 &&
            group.categoryRefs.includes(category[0])
          ) {
            return false;
          } else if (group.categoryRefs.includes(folderCategory) && !category.length) {
            return true;
          } else {
            return false;
          }
        });
      } catch (e) {
        console.error('Computed Search Groups Error:', e);

        return [];
      }
    },
    searchGroupsWithoutFolder() {
      return this.$store.getters['catalog/searchGroups'].filter((group) => !group.categoryRefs.length);
    }
  },
  methods: {
    getLink(group) {
      try {
        const { categoryList = [], topicList = {} } = group;
        const filters = { ...topicList };

        if (categoryList.length) {
          filters['category'] = categoryList;
        }

        return this.toLocalePath(
          this.$catalogUrlService.buildUrl({
            folderCategory: group.folderCategory,
            filters: filters
          })
        );
      } catch (e) {
        console.error('Get Link Error:', e);

        return '';
      }
    }
  }
};
