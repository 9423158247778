<template>
  <uc-modal v-model="modalDisplay">
    <div class="basket-notification">
      <div v-if="showAmountModal" class="basket-notification_content">
        <div class="basket-notification_icon">
          <div class="icon-basket"></div>
        </div>
        <div v-if="showAmountTitle" class="basket-notification_content">
          <div class="basket-notification_title">
            {{ $t('К сожалению, товар') }} "{{ title.avalaibleTitle }}" {{ $t('из Вашей корзины закончился') }}
          </div>
          <uc-link
            :to="localePath({ name: 'product-catalog-page', params: productCategorySlug })"
            class="basket-notification_link"
          >
            <uc-button class="uc-button-blue uc-button-medium" @click="hideModal()">{{
              $t('Смотреть похожие')
            }}</uc-button>
          </uc-link>
        </div>
        <div v-if="!showAmountTitle" class="basket-notification_content">
          <div class="basket-notification_title">
            {{ $t('К сожалению, некоторые товары из вашей корзины закончились') }}
          </div>
          <uc-link :to="localePath({ name: 'basket-page' })" class="basket-notification_link">
            <uc-button class="uc-button-blue uc-button-medium" @click="hideModal()">{{
              $t('Перейти в корзину')
            }}</uc-button>
          </uc-link>
        </div>
      </div>
      <div v-if="showCountModal" class="basket-notification_content">
        <div class="basket-notification_icon">
          <div class="icon-basket"></div>
        </div>
        <div v-if="showCountTitle" class="basket-notification_title">
          {{ $t('К сожалению, товар') }} "{{ title.countTitle }}" {{ $t('в запрашиваемом количестве недоступен') }}.
        </div>
        <div v-if="!showCountTitle" class="basket-notification_title">
          {{ $t('К сожалению, некоторые товары из вашей корзины недоступны') }}.
        </div>
      </div>
    </div>
  </uc-modal>
</template>

<script>
import NavigationRouteMixin from '~/src/components/navigation/mixin/NavigationRoute.mixin.js';

export default {
  name: 'BasketNotification',

  mixins: [NavigationRouteMixin],
  data() {
    return {
      modalDisplay: false,
      showAmountModal: false,
      showCountModal: false,
      showAmountTitle: false,
      showCountTitle: false,
      productCategorySlug: '',
      title: {
        avalaibleTitle: '',
        countTitle: ''
      }
    };
  },
  computed: {
    showModalBasket() {
      return this.$basket.getShowBasketModal();
    },
    route() {
      return this.$route.path;
    }
  },
  mounted() {
    this.$eventBus.$on('basketService:basketItem:available', this.basketItemTotalAmountNotification);
    this.$eventBus.$on('basketService:basketItem:count', this.basketItemCountNotification);
  },
  beforeDestroy() {
    this.$eventBus.$off('basketService:basketItem:available', this.basketItemTotalAmountNotification);
    this.$eventBus.$off('basketService:basketItem:count', this.basketItemCountNotification);
  },
  methods: {
    hideModal() {
      this.modalDisplay = false;
    },
    basketItemTotalAmountNotification(products) {
      this.modalDisplay = false;
      this.showCountModal = false;
      this.modalDisplay = true;
      this.showAmountModal = true;
      if (Array.isArray(products) && products.length === 1) {
        const product = products[0];
        this.title.avalaibleTitle = (product && product.title) || '';
        this.$api.SearchService.getProduct(product.slug)
          .then(({ data }) => {
            this.productCategorySlug = data.category.slug
              ? this.buildParams(data.folderCategory.slug, data.category.slug)
              : this.buildParams(data.folderCategory.slug);
          })
          .catch((e) => {
            if (this.$sentry) {
              this.$sentry.captureException(e);
            }
            console.error('Get Product By Slug Error', e);
          });
        this.showAmountTitle = true;
      } else {
        this.showAmountTitle = false;
      }
    },
    basketItemCountNotification(products) {
      this.modalDisplay = false;
      this.showAmountModal = false;
      this.modalDisplay = true;
      this.showCountModal = true;
      if (Array.isArray(products) && products.length === 1) {
        const product = products[0];
        this.title.countTitle = (product && product.title) || '';
        this.showCountTitle = true;
      } else {
        this.showCountTitle = false;
      }
    }
  }
};
</script>
