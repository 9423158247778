<template>
  <section>
    <uc-link
      v-if="$store.state.user.auth"
      :to="localePath({ name: 'wish-list-page' })"
      rel="nofollow"
      class="wish-list__counter"
    >
      <div class="counter__item">
        <svg class="counter__item-img">
          <use xlink:href="#hearth" />
        </svg>
        <div v-if="wishListItemCount > 0" class="counter__item-text">
          <span>{{ wishListItemCount }}</span>
        </div>
        <div class="counter__text">{{ $t('Избранное') }}</div>
      </div>
    </uc-link>
    <div v-if="!$store.state.user.auth" class="wish-list__counter" @click="clickHandler">
      <div class="counter__item">
        <svg class="counter__item-img">
          <use xlink:href="#hearth" />
        </svg>
        <div v-if="wishListItemCount > 0" class="counter__item-text">
          <span>{{ wishListItemCount }}</span>
        </div>
        <div class="counter__text">{{ $t('Избранное') }}</div>
      </div>
    </div>
  </section>
</template>

<script>
import { RouterMixin } from 'u-mixins';

export default {
  mixins: [RouterMixin],
  data() {
    return {
      isWishListPage: false,
      isClicked: false
    };
  },
  computed: {
    wishListItemCount() {
      return this.$store.state.wishList.wishListProductIds.length || '';
    }
  },
  watch: {
    '$route.fullPath': {
      handler(path) {
        this.isWishListPage = path === '/wish-list';
      },
      immediate: true
    }
  },
  mounted() {
    this.$eventBus.$on('auth:session:login', this.redirectToWishList);
  },
  beforeDestroy() {
    this.$eventBus.$off('auth:session:login', this.redirectToWishList);
  },
  methods: {
    clickHandler() {
      this.isClicked = true;
      this.$eventBus.$emit('modals:register:open', 'login');
    },
    redirectToWishList() {
      if (this.isClicked) {
        setTimeout(() => {
          this.moveToByName('wish-list-page');
          this.isClicked = false;
        });
      }
    }
  }
};
</script>
