<template>
  <section>
    <div class="widget-wrapper__list">
      <template v-for="(item, index) in basketItems">
        <uc-link
          :key="`basket-item-link-${index}`"
          :to="localePath({ name: 'product-detail-page', params: { id: item.slug } })"
          class="wrapper-list__item"
        >
          <div class="list-item__info">
            <uc-image class="item-info__img" :img="item.currentImage" alt="img" :width="`100`" />
            <div class="item-info__title">
              {{ item.title }}
            </div>
          </div>
          <template v-if="item.itemType !== ITEM_TYPE_GIFT">
            <div v-if="item.isAvailableForOrder" class="list-item__price">
              <span class="item-price__count">{{ item.count ? item.count : 1 }}&nbsp;x&nbsp;</span>
              <span class="item-price__sum">{{ getActualPrice(item) | currency }}</span>
            </div>
            <div v-else class="list-item__not-available">{{ $t('Нет в наличии') }}</div>
          </template>
          <template v-if="item.itemType === ITEM_TYPE_GIFT">
            <div class="list-item__price">
              <span class="item-price__count">{{ item.count ? item.count : 1 }}&nbsp;x&nbsp;</span>
              <img class="item-price__gift" src="https://static.ma.com.ua/assets/svg/ma_gift.svg" alt="gift" />
            </div>
          </template>
        </uc-link>
      </template>
    </div>

    <div class="widget-wrapper__price">
      <div class="wrapper-price__total">
        <div class="price-total__text">{{ $t('Общая сумма') }}:</div>
        <div class="price-total__sum">{{ totalPrice.totalFinalPrices | currency }}</div>
      </div>
      <div v-if="totalPrice.totalCheckedProfit > 0" class="wrapper-price__economy">
        <div class="price-economy__text">{{ $t('Экономия') }}:</div>
        <div class="price-economy__sum">{{ totalPrice.totalCheckedProfit | currency }}</div>
      </div>
    </div>
    <div class="widget-wrapper__control">
      <uc-link :to="localePath({ name: 'basket-page' })" class="wrapper-control__basket">{{ $t('В корзину') }}</uc-link>
      <uc-link v-if="!isEmptyAvailable" :to="localePath({ name: 'order-page' })" class="wrapper-control__order">{{
        $t('Оформить заказ')
      }}</uc-link>
    </div>
  </section>
</template>

<script>
import { basketModels } from 'u-models';
const { ITEM_TYPE_GIFT } = basketModels.basketItemType;

export default {
  computed: {
    isEmptyAvailable() {
      return !this.$store.state.basket.basket || this.$store.state.basket.basket.isEmptyAvailable;
    },
    basketItems() {
      return (this.$store.state.basket.basket && this.$store.state.basket.basket.basketItems) || [];
    },
    totalPrice() {
      return this.$basket.getTotalBasketPrice();
    },
    ITEM_TYPE_GIFT: () => ITEM_TYPE_GIFT
  },
  methods: {
    getActualPrice(item) {
      let price = item.price.recommendedPrice || 0;

      if (item.price.recommendedPrice > item.price.sellingPrices) {
        price = item.price.sellingPrices;
      }

      if (item.price.sellingPrices > item.checkedCost / item.checkedQuantity) {
        price = item.checkedCost / item.checkedQuantity;
      }

      return price;
    }
  }
};
</script>
