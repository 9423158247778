<template>
  <uc-modal v-model="isModalVisible">
    <div
      class="subscription-modal subscription-modal-background"
      :class="{ subscribed: isThanksText, exists: isAlreadyExists }"
    >
      <div v-if="!isThanksText && !isAlreadyExists" class="subscription-modal_wrap">
        <div class="modal-text">{{ $t('Дарим') }}</div>
        <div class="modal-text__bonus">30 грн {{ $t('кешбэка') }}</div>
        <div class="modal-text">{{ $t('на первую покупку') }}</div>
        <div class="modal-text_blue">{{ $t('за подписку') }}</div>
        <UcForm form-class="modal-form" :handler="submit">
          <uc-input
            v-model="userEmail"
            rules="required|email"
            name="E-mail"
            class="form-input"
            type="email"
            placeholder="E-mail"
          />
          <uc-button class="form-submit" type="submit">{{ $t('Получить') }}</uc-button>
        </UcForm>
      </div>
      <div v-if="isThanksText" class="modal-text__thnx">{{ $t('Спасибо') }}<br />{{ $t('за подписку') }}!</div>
      <div v-if="isAlreadyExists" class="modal-text__exists">{{ $t('Вы уже') }}<br />{{ $t('подписаны') }}!</div>
    </div>
  </uc-modal>
</template>

<script>
const STORE_NAME = 'bonus.show_subscribe_modal';
const TIME_BEFORE_OPENING = 20000;
const DAY_COUNT_IF_CLOSE = 7;
const DAY_COUNT_IF_SUBSCRIBE = 730;
const USER_ALREADY_EXIST_EMAIL = 1211;

export default {
  name: 'SubscriptionModal',
  data() {
    return {
      isModalVisible: false,
      userEmail: null,
      isThanksText: false,
      isAlreadyExists: false
    };
  },
  computed: {
    isAuth() {
      return this.$store.getters['user/isAuth'];
    },
    isSubscribeModal() {
      return this.$bonus.subscribeModalStatus(STORE_NAME);
    }
  },
  watch: {
    isModalVisible(isOpen) {
      this.$eventBus.$emit('general:body:hold-scroll', isOpen);
      if (!isOpen) {
        this.setTime();
      }
    }
  },
  mounted() {
    setTimeout(() => {
      this.isModalVisible = !this.isAuth && this.isSubscribeModal;
    }, TIME_BEFORE_OPENING);
  },
  beforeDestroy() {
    this.$eventBus.$emit('general:body:hold-scroll', false);
    this.isThanksText = false;
  },
  methods: {
    setTime() {
      let days = DAY_COUNT_IF_CLOSE;

      if (this.isThanksText) {
        days = DAY_COUNT_IF_SUBSCRIBE;
      }

      this.$bonus.setSubscribeModalTime(STORE_NAME, days);
    },
    submit() {
      this.$api.UserService.eSputnikSubscribe('widget', this.userEmail)
        .then(() => {
          this.isThanksText = true;
          this.$gtm.setESEmailAction(this.userEmail);
        })
        .catch((error) => {
          if (error.response && error.response.data.code && error.response.data.code === USER_ALREADY_EXIST_EMAIL) {
            this.isAlreadyExists = true;
          } else {
            console.error('Subscribe Error:', error);
          }
        });
    }
  }
};
</script>

<style lang="scss" src="~/assets/scss/modals/_subscription-modal.scss" />
