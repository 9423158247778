<template>
  <uc-modal v-model="modalDisplay" class="dialog-wish-list-move" :close-button="false">
    <div class="uc-modal-content">
      <div class="modal-wish-list-move">
        <div v-if="loading" class="wish-list-loading">
          <loading-spinner />
        </div>
        <UcForm :handler="saveToWishList">
          <chose-wish-list
            ref="form"
            :user-wish-lists="userWishLists"
            :current-list-id="currentListId"
            add-to-list-title="Переместить в список"
            add-to-new-list-title="Переместить в новый список"
            @destination="updateDestination"
            @form-status="saveToWishList"
          />

          <div class="popup-window-btns-wrap">
            <uc-button class="uc-button-black wish-list-modal-btn" type="submit">{{ $t('Переместить') }}</uc-button>
            <button type="button" class="bbs-button_blue" @click="hideModal()">{{ $t('Отмена') }}</button>
          </div>
        </UcForm>
      </div>
    </div>
  </uc-modal>
</template>

<script>
import LoadingSpinner from '~/src/components/page-blocks/LoadingSpinner.vue';
import ChoseWishList from 'u-wishlist/lib/components/form/ChooseWishList.vue';
import MoveToWishListMixin from 'u-wishlist/lib/mixins/MoveToWishList.mixin.js';

export default {
  components: { LoadingSpinner, ChoseWishList },
  mixins: [MoveToWishListMixin]
};
</script>
