<template>
  <uc-modal v-model="visible" class="modal-bonus">
    <section id="bonus-modal" class="bonus-modal">
      <div class="auth-nobonus_text">
        {{ userName }}! <br />{{ $t('Получайте') }} <br v-if="isScreenMobile" />
        <span class="bonus-modal_double">{{ $t('двойной кешбэк') }}</span>
        <br />{{ $t('в честь') }} <span class="bonus-modal_birthday">{{ $t('дня рождения') }}</span>
        <br v-if="!isScreenMobile" />{{ $t('вашего малыша') }}<br />
        {{ $t('c') }} {{ startDate }} {{ $t('по') }} {{ endDate }}
      </div>
      <div class="bonus-modal-button">
        <div class="uc-button-black bonus-button" @click="hideModal()">{{ $t('Продолжить покупки') }}</div>
      </div>
    </section>
  </uc-modal>
</template>

<script>
import ScreenWidth from '~/src/mixins/ScreenWidth';

const STORE_NAME = 'bonus.birthday_bonus_modal';
const DAYS_COUNT = 2;

export default {
  name: 'BirthdayBonusModal',
  mixins: [ScreenWidth],
  data() {
    return {
      visible: false,
      startDate: '',
      endDate: ''
    };
  },
  computed: {
    userName() {
      return this.$store.state.user.data.firstName;
    },
    locale() {
      return this.$i18n.locale === 'ua' ? 'uk' : this.$i18n.locale;
    }
  },
  watch: {
    visible(isOpen) {
      this.$eventBus.$emit('general:body:hold-scroll', isOpen);
    },
    '$store.state.bonus.profile': {
      handler: function (profile) {
        this.handlePromo(profile);
      },
      immediate: false,
      deep: true
    },
    '$i18n.locale': {
      handler() {
        this.handlePromo(this.$store.state.bonus.profile);
      }
    }
  },
  beforeDestroy() {
    this.$eventBus.$emit('general:body:hold-scroll', false);
  },
  methods: {
    hideModal() {
      this.visible = false;
    },
    handlePromo(profile) {
      if (!profile) {
        return;
      }
      const [event] = profile.promoRates || [];
      const date = new Date().getTime();

      if (event && this.isDisplayModal(event, date)) {
        this.startDate = this.getDateFormat(event.startDate);
        this.endDate = this.getDateFormat(event.endDate);

        this.$lstore.setStore(STORE_NAME, date);
        setTimeout(() => {
          this.visible = true;
        }, 40000);
      } else {
        const userStoreName = this.$lstore.getUserStoreName(STORE_NAME);
        this.$lstore.removeStore(userStoreName);
      }
    },
    isDisplayModal(event, date) {
      const startEvent = new Date(event.startDate).getTime();
      const endEvent = new Date(event.endDate).getTime();
      if (!(date > startEvent && date < endEvent)) {
        return false;
      }

      const displayModalDate = this.$lstore.getUserStore(STORE_NAME) || 0;
      const interval = 1000 * 60 * 60 * 24 * DAYS_COUNT;

      return displayModalDate + interval < date;
    },
    getDateFormat(date) {
      const options = { month: 'long', day: 'numeric' };
      return new Date(date).toLocaleString(this.locale, options);
    }
  }
};
</script>
