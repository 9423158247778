<template>
  <div
    v-click-outside="hideWidgetClick"
    class="auth-widget"
    @mouseover="displayWidgetMouse()"
    @mouseleave="hideWidgetMouse()"
  >
    <div class="auth-widget__button" @click="displayWidgetClick()">
      <svg class="widget-button__img" :class="{ active: isVisible }">
        <use xlink:href="#user" />
      </svg>
      <div class="widget-button__text">
        {{ $store.state.user.data.firstName || $t('Мой кабинет') }}
      </div>
    </div>
    <div v-if="isVisible" class="auth-widget__block">
      <div class="auth-widget__wrapper">
        <div v-if="!$store.state.user.auth">
          <div class="uc-button-black widget-wrapper__login" @click="displayDialog('login')">{{ $t('Войти') }}</div>
          <div class="uc-button uc-button-white-black widget-wrapper__register" @click="displayDialog('registration')">
            {{ $t('Регистрация') }}
          </div>
        </div>

        <div v-if="$store.state.user.auth">
          <auth-bonus v-if="isActiveBonusProgram" />
          <div class="widget-wrapper__link" @click="moveToByName('profile-page')">{{ $t('Личная информация') }}</div>
          <div class="widget-wrapper__link" @click="moveToByName('order-history-page')">
            {{ $t('История заказов') }}
          </div>
          <div class="widget-wrapper__link" @click="moveToByName('wish-list-page')">{{ $t('Список желаний') }}</div>
          <div v-if="isActiveBonusProgram" class="widget-wrapper__link" @click="moveToByName('bonus-program-page')">
            {{ $t('МА Кешбэк') }}
          </div>
          <div class="widget-wrapper__link" @click="moveToByName('subscription-profile-page')">
            <span v-if="isAbonementMember">
              {{ $t('МА Подписка: до') }} {{ userData.subscriptionDiscountEndDate | date }}
            </span>
            <span v-else>
              {{ $t('МА Подписка') }}
            </span>
          </div>
          <div class="widget-wrapper__link" @click="moveToByName('user-review-page')">{{ $t('Мои отзывы') }}</div>
          <div class="widget-wrapper__logout" @click="logout()">{{ $t('Выйти') }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { RouterMixin, ScreenWidthMixin } from 'u-mixins';
import vClickOutside from 'v-click-outside';
import AuthBonus from '~/src/components/bonus/AuthBonus';

export default {
  directives: {
    clickOutside: vClickOutside.directive
  },
  components: {
    AuthBonus
  },
  mixins: [RouterMixin, ScreenWidthMixin],
  data() {
    return {
      isVisible: false,
      isActiveBonusProgram: process.env.BONUS_PROGRAM
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      userData: (state) => state.user.data,
      isAuth: (state) => state.user.auth,
      profile: (state) => state.bonus.profile
    }),
    ...mapGetters({
      isAbonementMember: 'user/isAbonementMember'
    })
  },
  watch: {
    '$route.path': {
      handler() {
        this.hideWidget();
      }
    }
  },
  created() {
    this.$bonus.getBonusProfileIfNotExist();
  },
  async mounted() {
    await this.$nextTick();
    const isLogged = await this.$auth.restoreUserSession();
    this.$gtm.checkUser(this.user, 'refresh');
    this.$gtm.setUserInfo(this.user);
    if (!isLogged) {
      this.startOauth();
    }
  },
  methods: {
    ...mapActions({
      loginByOauth: 'user/loginByOauth'
    }),
    displayWidgetMouse() {
      if (!this.isScreenDesktop) {
        return;
      }

      this.isVisible = true;
    },
    displayWidgetClick() {
      if (this.isScreenDesktop) {
        return;
      }

      this.isVisible = true;
    },
    hideWidget() {
      this.isVisible = false;
    },
    hideWidgetMouse() {
      if (!this.isScreenDesktop) {
        return;
      }

      this.hideWidget();
    },
    hideWidgetClick() {
      if (this.isScreenDesktop) {
        return;
      }

      this.hideWidget();
    },
    displayDialog(tab) {
      this.$eventBus.$emit('modals:register:open', tab);
      this.hideWidget();
    },
    logout() {
      if (this.isActiveBonusProgram) {
        this.$eventBus.$emit('auth_forms:login_tab:success_notice:bonus', false);
      }
      try {
        this.$auth.logout();
        if (this.$route.meta.isAuthorized) {
          this.moveToByName('main');
        } else {
          this.hideWidget();
        }
        this.$gtm.setUserInfo(this.user);
      } catch (e) {
        console.error('Authorisation Error: ', e);
      }
      this.hideWidget();
    },
    async startOauth() {
      const { code, provider } = this.$route.query;

      if (!code || !provider) {
        return;
      }

      this.moveToByName('main');
      try {
        const { status } = await this.loginByOauth({ code, provider });
        this.$eventBus.$emit('auth_forms:login_tab:success_notice:display');
        this.$eventBus.$emit('user:auth:login');
        if (status === 206) {
          this.$notify({
            group: 'default',
            type: 'success',
            title: 'Соцсеть подключена',
            text: 'Введите номер телефона для продолжения регистрации'
          });
        }
      } catch (error) {
        this.$sentry?.captureException(error);
        this.$notify({
          group: 'default',
          type: 'error',
          title: this.$t('Ошибка авторизации'),
          text: this.$t('Спробуйте пізніше або інший метод авторизації.')
        });
      }
    }
  }
};
</script>

<style lang="scss" src="~/assets/scss/auth-widget/_auth-widget.scss" />
