<template>
  <uc-modal v-model="visibility" :close-button="false">
    <div class="gifts-modal">
      <div class="gifts-modal__content">
        <div class="modal-content__img">
          <img src="https://static.ma.com.ua/assets/svg/ma_gift.svg" alt="gift" />
        </div>
        <div class="modal-content__info">
          <div class="content-info__title">{{ $t('Добавлен подарок') }}!</div>
          <div class="content-info__text">{{ $t('В вашу корзину добавлен подарок за участие в акции') }}</div>
        </div>
      </div>
      <div class="gifts-modal__button">
        <template v-if="isSelectable">
          <uc-button class="uc-button-white-blue" @click="hide()">{{ $t('Выберу потом') }}</uc-button>
          <uc-button class="uc-button-blue" @click="toBasket()">{{ $t('Выбрать подарок') }}</uc-button>
        </template>
        <uc-button v-if="!isSelectable" class="uc-button-blue" @click="hide()">{{
          $t('Спасибо за подарок')
        }}</uc-button>
      </div>
    </div>
  </uc-modal>
</template>

<script>
import SaveGiftMixin from '~/src/components/basket/mixin/SaveGift.mixin.js';

const GIFT_STORE_NAME = 'viewed_gift_items';

export default {
  name: 'GiftsModal',
  mixins: [SaveGiftMixin],
  data() {
    return {
      visibility: false,
      isSelectable: false
    };
  },
  computed: {
    basketModel() {
      return this.$basket.getBasket();
    }
  },
  watch: {
    '$store.state.gift.gifts': {
      handler(gifts) {
        if (gifts.length) {
          this.checkModalVisibility(gifts);
        }
      }
    }
  },
  beforeDestroy() {
    this.hide();
  },
  methods: {
    isShow(isSelectable = false) {
      this.isSelectable = isSelectable;
      this.visibility = true;
    },
    hide() {
      this.visibility = false;
    },
    toBasket() {
      if (this.$route.name !== `basket-page___${this.$i18n.locale}`) {
        this.$router.push(this.localePath({ name: 'basket-page' }));
      }

      this.hide();
    },
    checkModalVisibility(gifts) {
      const localStore = this.$lstore.getStoreByName(GIFT_STORE_NAME) || [];

      const newGifts = gifts.filter((gift) => {
        return (
          localStore.filter((storeItem) => {
            return storeItem === gift.id;
          }).length === 0
        );
      });

      if (Array.isArray(newGifts) && !newGifts.length) {
        return;
      }

      const noSelectableGifts = newGifts.filter((item) => !item.isSelectable);
      const selectableGifts = newGifts.filter((item) => item.isSelectable);

      if (!selectableGifts.length && !noSelectableGifts.length) {
        return;
      }

      if (selectableGifts.length) {
        this.setNoSelectableGiftInLocalStore(noSelectableGifts, localStore);
        selectableGifts.every((item) => localStore.push(item.id));
        this.$lstore.setStoreByName(GIFT_STORE_NAME, localStore);
        this.isShow(true);
      } else {
        this.setNoSelectableGiftInLocalStore(noSelectableGifts, localStore);
        this.isShow();
      }
    },
    setNoSelectableGiftInLocalStore(noSelectableGifts, localStore) {
      if (!noSelectableGifts.length) {
        return;
      }

      noSelectableGifts.every((item) => {
        const giftInBasket = this.basketModel.getGiftInBasket(item);
        localStore.push(item.id);
        this.saveGift(item.nomenclatureList[0], giftInBasket, item.id);
      });
    }
  }
};
</script>
<style lang="scss" src="~/assets/scss/gifts/_gifts.scss" />
